<template>
  <div class="body" id="app">
    <img src="@/assets/images/toutiao/1.png" alt="" />
    <img src="@/assets/images/toutiao/2.png" alt="" />
    <toutiao-form></toutiao-form>
    <img src="@/assets/images/toutiao/3.png" alt="" />
    <img src="@/assets/images/toutiao/4.png" alt="" />
    <toutiao-form></toutiao-form>
    <div class="footer">
      <div>
        <div class="footer-title">敬请垂询：</div>
        <div class="footer-row">热线：13268012449</div>
        <div class="footer-row">
          微信：13268012449 <span style="color: #00ffff">复制</span>
        </div>
        <div class="footer-row">邮箱：gnsl.service@suzi.com.cn</div>
      </div>

      <div class="footer-bottom">
        <p>市场推广：广东省数字羽田科技有限公司</p>
        <p>技术支持：广州市广农数链信息科技有限公司</p>
      </div>
    </div>
  </div>
</template>

<script>
import toutiaoForm from './views/toutiaoForm.vue'
export default {
  components: {
    toutiaoForm
  }
}
</script>

<style lang="scss" scoped>
.body {
  background: linear-gradient(90deg, #3427e4, #051b82);
}

.body img {
  width: 100%;
}

.footer {
  padding: 20px 34px 44px;
  color: #fff;
}
.footer-title {
  font-size: 18px;
}
.footer-row {
  font-size: 13px;
}

.footer-bottom {
  padding-top: 38px;
  text-align: center;
}
</style>
