<template>
  <div class="card-box">
    <div class="card">
      <div class="card-tips">
        <img src="@/assets/images/toutiao/tips.png" alt="" />
        截止目前已有
        <span class="num">{{ total }}</span>
        人加入咨询表单
      </div>
      <div class="card-item">
        <div class="card-item-label">
          姓名
          <span class="must"></span>
        </div>
        <input type="text" placeholder="请输入" v-model="params.name" />
      </div>

      <div class="card-item">
        <div class="card-item-label">
          手机
          <span class="must"></span>
        </div>
        <input
          type="tel"
          placeholder="请输入"
          v-model="params.phone"
          maxlength="11"
        />
      </div>

      <div class="card-item">
        <div class="card-item-label">
          验证码
          <span class="must"></span>
        </div>
        <input
          type="tel"
          placeholder="请输入"
          v-model="sms_code"
          maxlength="11"
          style="width: 32vw"
        />
        <span
          style="flex-shrink: 0; color: #f28900; font-size: 3.2vw"
          @click="sendCode"
          >{{ timeNum ? timeNum + "后重新获取" : "获取验证码" }}</span
        >
      </div>

      <div class="card-item">
        <div class="card-item-label">
          所在城市
          <span class="must"></span>
        </div>
        <input
          type="text"
          placeholder="请输入(例:广东省广州市)"
          v-model="params.area"
        />
      </div>

      <div class="sub-btn" @click="register">一键获取详细资料</div>
    </div>
  </div>
</template>

<script>
import { register, applyForm, getSmsCode } from '@/api/toutiao'

export default {
  data () {
    return {
      params: {
        name: '',
        phone: '',
        type: '',
        industry: '',
        apply_type: '',
        province: '',
        area: '',
        channel_code: 'z4BeyLIc'
      },
      sms_code: '',
      timeNum: 0,
      total: '581',
      loading: null
    }
  },
  methods: {
    sendCode () {
      if (this.timeNum) {
        return
      }

      getSmsCode({ template_id: '6', phone_number: this.params.phone }).then(res => {
        this.timeNum = 60
        const interval = setInterval(() => {
          this.timeNum--
          if (this.timeNum <= 0) {
            clearInterval(interval)
          }
        }, 1000)
      }).catch(err => {
        this.$toast(err.msg || '获取验证码失败')
      })
    },
    register () {
      this.loading = this.$toast.loading(
        {
          duration: 0
        }
      )
      register({
        phone: this.params.phone,
        sms_code: this.sms_code
      }).then(res => {
        this.submitForm()
      }).catch(err => {
        this.$toast(err.msg || '请求失败')
      })
    },
    submitForm () {
      applyForm(this.params).then(res => {
        window.location.href = 'https://static-bc.suzi.com.cn/static/channel/new_market_static/invite_install.html?channel=z4BeyLIc&from=toutiao'
      }).catch(err => {
        this.$toast(err.msg || '请求失败')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.card-box {
  padding: 5.3333vw 7.4667vw 2.1333vw;
}

.card {
  background: #fff;
  border-radius: 1.3333vw;
  padding: 5.6vw 3.7333vw 2.1333vw;
}

.card-tips {
  border-radius: 2.6667vw;
  box-shadow: 0vw 0.2667vw 1.3333vw 0vw rgba(76, 78, 74, 0.16);
  height: 9.3333vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #707070;
}

.card-tips .num {
  color: #f28900;
  font-weight: 700;
  padding: 0 1.8667vw;
}

.card-tips img {
  height: 4.8vw;
  width: 4.8vw !important;
  margin-right: 0.8vw;
}

.card-item {
  height: 12.2667vw;
  display: flex;
  align-items: center;
  border-bottom: #dddddd solid 0.2667vw;
}

.card-item-label {
  position: relative;
  color: #333;
  width: 21.3333vw;
}

.must {
  position: relative;
}

.must::before {
  content: "*";
  position: absolute;
  left: 1.3333vw;
  top: 0;
  color: #ff2e60;
}

.card-item input {
  border: none;
  flex: 1;
  /* margin-left: 12vw; */
}

.sub-btn {
  height: 10.6667vw;
  color: #fff;
  background-color: #f28900;
  border-radius: 0.8vw;
  margin-top: 4.5333vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
