
import { api } from '@/plugins/axios'

export function getSmsCode (params) {
  if (!/^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(params.phone_number)) {
    return Promise.reject({
      msg: '请输入正确的手机号码',
      code: '9000'
    })
  }

  return api('/send/sms/code/', params)
}

export function register (params) {
  if (!/^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(params.phone)) {
    return Promise.reject({
      msg: '请输入正确的手机号码',
      code: '9000'
    })
  }

  if (!params.sms_code) {
    return Promise.reject({
      msg: '请输入验证码',
      code: '9000'
    })
  }

  return api('/extended/new_user', params)
}

export function applyForm (params) {
  if (!params.name) {
    return Promise.reject({
      msg: '请输入姓名',
      code: '9000'
    })
  }

  if (!/^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(params.phone)) {
    return Promise.reject({
      msg: '请输入正确的手机号码',
      code: '9000'
    })
  }

  if (!params.area) {
    return Promise.reject({
      msg: '请输入所在城市',
      code: '9000'
    })
  }

  return api('/extended/cooperation/apply', params)
}

export function inviteRecord (params) {
  return api('/invite/user/record', params)
}
